import * as React from "react"
import { Homepage } from "@fidize/website.pages.home.homepage"

const IndexPage = () => {
  return (
    <>
      <head>
        <title>Fidize - L'ultimo biglietto da visita che comprerai</title>
      </head>
      <Homepage />
    </>
  )
}

export default IndexPage
